const fake = [
	{
		id: 149,
		name: "est_reiciendis",
		namespace: "post",
		namespace_ranking: 9,
		score: 32,
		tags: ["doo"],
		total_ranking: 49,
		total_votes: 1,
		type: "like",
	},
	{
		id: 150,
		name: "foo",
		namespace: "post",
		namespace_ranking: 9,
		score: 32,
		tags: ["doo"],
		total_ranking: 49,
		total_votes: 1,
		type: "like",
	},
	{
		id: 1493,
		name: "bar",
		namespace: "post",
		namespace_ranking: 9,
		score: 32,
		tags: ["doo"],
		total_ranking: 49,
		total_votes: 1,
		type: "like",
	},
	{
		id: 1492,
		name: "bum",
		namespace: "post",
		namespace_ranking: 9,
		score: 32,
		tags: ["doo"],
		total_ranking: 49,
		total_votes: 1,
		type: "like",
	},
];

export default fake;
